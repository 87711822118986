$(() => {
  const $sidepanel = $("#sidepanel");
  const $sidepanelBtn = $(".btn-sidepanel");

  $sidepanelBtn.click((e) => {
    e.preventDefault();

    $sidepanel.toggleClass("show");
  });
});

$(() => {
  const $sidepanel2 = $("#sidepanel2");
  const $sidepanelBtn2 = $(".btn-sidepanel2");
  $sidepanelBtn2.click((e) => {
    e.preventDefault();
    $sidepanel2.toggleClass("show");
  });
});
