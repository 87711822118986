$(() => {
  const $html = $('html');
  const $iframe = $('iframe');

  /**
   * Send contrast toolbar theme to claims app
   */
  const sendThemeMessage = ($el) => {
    if (!$('iframe')[0]) {
      return false;
    }
    const iframeContent = $('iframe')[0].contentWindow;
    const message = $el.hasClass('a11y-contrast') ? 'dark' : 'light';

    iframeContent.postMessage(message);
  };

  // Run at load iframe
  $iframe.on('load', () => {
    sendThemeMessage($html)
  });
  
  const $contrastToolbarBtn = $('.toolbar-behavior-contrast');

  // Run at click .toolbar-behavior-contrast
  $contrastToolbarBtn.click(() => {
    sendThemeMessage($html);
  });
});